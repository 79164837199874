import React, { useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Navbar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const closeonClickInMobile = () => {
    if (isNavExpanded) {
      setIsNavExpanded(!isNavExpanded);
    }
  };
  return (
    <nav className="navigation">
      <div className="logo">
        <Link to="/">
          <LazyLoadImage src={logo} width="300px" />
        </Link>
      </div>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul>
          <li>
            <Link to="/" onClick={closeonClickInMobile}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={closeonClickInMobile}>
              About
            </Link>
          </li>
          <li>
            <Link to="/projects" onClick={closeonClickInMobile}>
              Projects
            </Link>
          </li>
          <li>
            <Link to="/gallery" onClick={closeonClickInMobile}>
              Gallery
            </Link>
          </li>
          <li>
            <Link to="/services" onClick={closeonClickInMobile}>
              Services
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={closeonClickInMobile}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
