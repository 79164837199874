import React from "react";
import banner from "../../images/home/banner.jpg";
import "./style.css";
import image1 from "../../images/projects/HANDLOOMED.jpg";
import image2 from "../../images/projects/Handloomed-II-1.jpg";
import image3 from "../../images/projects/IMG_1028.jpg";
import image4 from "../../images/projects/IMG_3046.jpg";
import image5 from "../../images/projects/IMG_3084.jpg";
import image6 from "../../images/projects/IMG_4108.jpg";
import image7 from "../../images/projects/IMG_5577.jpg";
import image8 from "../../images/projects/connection-image-with-ars-yarns-A.jpg";
import image9 from "../../images/projects/connection-image-with-ars-yarns.jpg";
import image10 from "../../images/projects/Alhambra-Stripe-red.jpg";
import image11 from "../../images/projects/geometric3.jpg";
import image12 from "../../images/projects/Boorah-Bubble-red.jpg";
import image13 from "../../images/projects/IMG_5584.jpg";
import image14 from "../../images/projects/IMG_5582.jpg";
import image15 from "../../images/projects/IMG_5787.jpg";
import image16 from "../../images/projects/IMG_5588.jpg";
import Carousel from "../../components/Carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Projects = () => {
  return (
    <>
      <div id="topImage">
        <LazyLoadImage src={banner} height="600px" width="100%" />
        <div id="textAboveImage">
          <h1 id="homeHeading">My Projects</h1>
        </div>
      </div>

      <div className="projectContainer">
        <h2 className="heading2">HAND KNOTTED</h2>
        <p id="handKnotText">
          A completely hand-made product created from knotting and looming
          techniques using assorted fibers, dye application, and specialized
          finishing processes.
        </p>
        <Carousel
          imageArray={[
            image1,
            image2,
            image3,
            image4,
            image5,
            image6,
            image7,
            image8,
            image9,
          ]}
        />
      </div>
      <br />
      <br />
      <div className="projectContainer">
        <h2 className="heading2">HAND TUFTED</h2>
        <p id="handKnotText">
          A handmade process where fibers are placed into a backing and the
          surface is finished with various expert surface manipulation
          techniques.
        </p>
        <Carousel
          imageArray={[
            image10,
            image11,
            image12,
            image13,
            image14,
            image15,
            image16,
          ]}
        />
      </div>
      <br />
      <br />
    </>
  );
};

export default Projects;
