import React from "react";
import banner from "../../images/home/banner.jpg";
import "./style.css";
import { Link } from "react-router-dom";
import { Button, Grid, useMediaQuery } from "@material-ui/core";
import { Phone } from "@material-ui/icons";

import image1 from "../../images/services/AWAKE-Visualization.jpg";
import image2 from "../../images/services/creating-rugs-from-artwork-through-grid-application.jpg";
import image3 from "../../images/services/custom-colorations-and-fiber-blends-for-collection-work.jpg";
import image4 from "../../images/services/specifications-for-custom-hand-tufted-rugs-assorted-sizes-and-shapes.jpg";
import image5 from "../../images/services/unique-applications-for-surfaces-II-1005x1024-1.jpg";
import image6 from "../../images/services/unique-applications-for-surfaces.jpg";
import image7 from "../../images/services/Screenshot-2023-06-11-163349-1.jpg";
import image8 from "../../images/services/Screenshot-2023-06-11-163738-1.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Services = () => {
  const matches = useMediaQuery("(min-width:600px) and (max-width: 1200px)");
  return (
    <>
      <div id="topImage">
        <LazyLoadImage src={banner} height="600px" width="100%" />
        <div id="textAboveImage">
          <h1 id="homeHeading">My Services</h1>
        </div>
      </div>
      <div id="servicesContainer">
        <p>
          My goal is to emphasize the unique services I provide with different
          surfaces.
        </p>
        <ul>
          <li>
            Create custom rug artwork for bespoke rugs including unique surface
            application specifications.
          </li>
          <li>Collection development.</li>
          <li>
            Color direction as per current trends, forecasting, and research
            methods.
          </li>
          <li>
            Consult and work with design teams on rug and carpet projects.
          </li>
          <li>
            Make rugs for clients and design teams through trusted rug artisans
            and deliver worldwide.
          </li>
          <li>
            Provide digital and practical training and education for design
            teams and individuals in rug design skills.
          </li>
          <li>
            Specialize in unique shapes and surfaces application for all
            handmade rug qualities.
          </li>
          <li>Recruitment and placement services.</li>
          <li>
            Travel and provide artisanal liaise services and on-site production
            counsel.
          </li>
        </ul>

        <Link to="/contact">
          <Button
            variant="contained"
            endIcon={<Phone />}
            color="primary"
            className="learnmoreButton1"
          >
            CONTACT ME
          </Button>
        </Link>
        <div id="serviceImageDiv">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, lg: 3 }}
          >
            <Grid item xs={12} sm={6} lg={4}>
              <LazyLoadImage
                src={image1}
                alt=""
                height="400px"
                className="imageService"
              />
              <p>AWAKE(Visualization)</p>
              <LazyLoadImage
                src={image2}
                alt=""
                height="400px"
                className="imageService"
              />
              <p>Creating rugs from artwork through grid application</p>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <LazyLoadImage
                src={image3}
                alt=""
                height="400px"
                className="imageService"
              />
              <p>Custom colorations and fiber blends for collection work</p>
              <LazyLoadImage
                src={image5}
                alt=""
                height="400px"
                className="imageService"
              />
              <p>Unique applications for surfaces II</p>
            </Grid>
            <Grid item xs={12} sm={matches ? 12 : 6} lg={4}>
              <div id="thirdGrid">
                <div>
                  <LazyLoadImage
                    src={image4}
                    alt=""
                    height="400px"
                    className="imageService"
                  />
                  <p>Unique applications for surfaces</p>
                </div>
                <div>
                  <LazyLoadImage
                    src={image6}
                    alt=""
                    height="400px"
                    className="imageService"
                  />
                  <p>
                    Specifications for custom hand-tufted rugs, assorted sizes
                    and shapes
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, lg: 3 }}
          >
            <Grid item xs={12} sm={6}>
              <a
                href="https://therugdesignersstudio.com/pdfs/Custom%20Artwork%20for%20Artist%20Rugs.pdf"
                target="_blank"
              >
                <LazyLoadImage
                  src={image7}
                  alt=""
                  height="600px"
                  className="imageService"
                />
              </a>

              <p>Custom Artwork for Artist Rugs</p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <a
                href="https://therugdesignersstudio.com/pdfs/custom artwork for rugs and products.pdf"
                target="_blank"
              >
                <LazyLoadImage
                  src={image8}
                  alt=""
                  height="600px"
                  className="imageService"
                />
              </a>
              <p>Custom artwork for rugs and products</p>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Services;
