import React from "react";
import "./style.css";

const Footer = () => {
  var dateobj = new Date();
  var dateObject = dateobj.getFullYear();
  return (
    <div id="footer">
      <p id="f1">
        Staten Island, New York, 10305, USA | Phone: 718-288-6165 /+1
        718-288-6165 | Email: dlhernandezinc@gmail.com
      </p>
      <span>Copyright © {dateObject} The Rug Designer's Studio</span>
    </div>
  );
};

export default Footer;
